<!--
--------------------------------------------------------------------------------
<copyright file="QuotesFiguresDetailed.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('report.quotes') }}</summary>
      <span>{{ $t('widget_info_text.quotes_figure') }}</span>
    </details>
    <details>
      <summary>{{ $t('report.business_console.nAccepted') }}</summary>
      <span> {{ $t('widget_info_text.quotes_figures_accepted') }}</span>
    </details>
    <details>
      <summary>{{ $t('report.business_console.nRejected') }}</summary>
      <span> {{ $t('widget_info_text.quotes_figures_rejected') }}</span>
    </details>
    <details>
      <summary>{{ $t('report.business_console.avgConversionTime') }}</summary>
      <span> {{ $t('widget_info_text.quotes_figures_avg_conv_time') }}</span>
    </details>
    <details>
      <summary>{{ $t('report.business_console.avgAmount') }}</summary>
      <span> {{ $t('widget_info_text.quotes_figures_avg_amount') }}</span>
    </details>
    <details>
      <summary>{{ $t('report.business_console.avgConvertedAmount') }}</summary>
      <span> {{ $t('widget_info_text.quotes_figures_avg_converted_amount') }}</span>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class QuotesFiguresDetailedInfo extends Vue {}
</script>
